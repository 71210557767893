import Head from "next/head";
import * as React from "react";
import "../styles/globals.css";
import type { AppProps } from "next/app";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import GoogleTagManager from "../components/Shared/GoogleTagManager";
import KeywordProvider from "../lib/context/keyword";
import LeadProvider from "../lib/context/lead";
import SolarProvider from "../lib/context/solar";

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <meta name="theme-color" content="#00263A" />
        <meta
          name="facebook-domain-verification"
          content="e0ncakg6fvmijuxj5v07zscjdv7d5k"
        />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon.png" />
      </Head>
      <noscript>
        <iframe
          width="0"
          height="0"
          style={{ display: "none", visibility: "hidden" }}
          src={`https://www.googletagmanager.com/ns.html?id=${process.env.GOOGLE_ANALYTICS}`}
        />
      </noscript>
      <GoogleTagManager />
      <div>
        <KeywordProvider>
          <LeadProvider>
            <SolarProvider>
              <Component {...pageProps} />
            </SolarProvider>
          </LeadProvider>
        </KeywordProvider>
      </div>
    </>
  );
};

export default MyApp;
