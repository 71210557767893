import * as React from "react";
import Cookies from "js-cookie";
import { useRouter } from "next/router";
import { KeywordContextInterface } from "../types";

export const KeywordContext = React.createContext<KeywordContextInterface>({
  source: undefined,
  setSource: (source: string | undefined) => {},
  gclid: undefined,
  setGclid: (gclid: string | undefined) => {},
  fbclid: undefined,
  setFbclid: (fbclid: string | undefined) => {},
  campaignid: undefined,
  setCampaignid: (campaignid: string | undefined) => {},
  adid: undefined,
  setAdid: (adid: string | undefined) => {},
  adsetid: undefined,
  setAdsetid: (adsetid: string | undefined) => {},
  targetid: undefined,
  setTargetid: (targetid: string | undefined) => {},
  adgroupid: undefined,
  setAdgroupid: (adgroupid: string | undefined) => {},
  keyword: undefined,
  setKeyword: (keyword: string | undefined) => {},
});

const KeywordProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const router = useRouter();
  const [source, setSource] = React.useState<string | undefined>(undefined);
  const [gclid, setGclid] = React.useState<string | undefined>(undefined);
  const [fbclid, setFbclid] = React.useState<string | undefined>(undefined);
  const [adid, setAdid] = React.useState<string | undefined>(undefined);
  const [adsetid, setAdsetid] = React.useState<string | undefined>(undefined);
  const [targetid, setTargetid] = React.useState<string | undefined>(undefined);
  const [keyword, setKeyword] = React.useState<string | undefined>(undefined);
  const [adgroupid, setAdgroupid] = React.useState<string | undefined>(
    undefined
  );
  const [campaignid, setCampaignid] = React.useState<string | undefined>(
    undefined
  );

  const cookieGetSet = (
    key: string,
    value: string | undefined,
    setter: (value: string) => void
  ) => {
    if (Cookies.get(key) !== value && value !== undefined) {
      Cookies.set(key, value as string);
      setter(value);
    }
  };

  React.useEffect(() => {
    if (!router.isReady) {
      return;
    }

    const {
      gclid,
      fbclid,
      campaignid,
      adid,
      adsetid,
      targetid,
      adgroupid,
      keyword,
    } = router.query;

    setSource(Cookies.get("fbclid") !== undefined ? "facebook" : "google");
    setFbclid(Cookies.get("fbclid"));
    setGclid(Cookies.get("gclid"));
    setCampaignid(Cookies.get("campaignid"));
    setAdid(Cookies.get("adid"));
    setAdsetid(Cookies.get("adsetid"));
    setTargetid(Cookies.get("targetid"));
    setAdgroupid(Cookies.get("adgroupid"));
    setKeyword(Cookies.get("keyword"));

    cookieGetSet("fbclid", fbclid as string | undefined, setFbclid);
    cookieGetSet("gclid", gclid as string | undefined, setGclid);
    cookieGetSet("campaignid", campaignid as string | undefined, setCampaignid);
    cookieGetSet("adid", adid as string | undefined, setAdid);
    cookieGetSet("adsetid", adsetid as string | undefined, setAdsetid);
    cookieGetSet("targetid", targetid as string | undefined, setTargetid);
    cookieGetSet("adgroupid", adgroupid as string | undefined, setAdgroupid);
    cookieGetSet("keyword", keyword as string | undefined, setKeyword);
  }, [
    router,
    setSource,
    setFbclid,
    setGclid,
    setCampaignid,
    setAdid,
    setAdsetid,
    setTargetid,
    setAdgroupid,
    setKeyword,
  ]);

  return (
    <KeywordContext.Provider
      value={{
        source,
        setSource,
        gclid,
        setGclid,
        fbclid,
        setFbclid,
        campaignid,
        setCampaignid,
        adid,
        setAdid,
        adsetid,
        setAdsetid,
        targetid,
        setTargetid,
        adgroupid,
        setAdgroupid,
        keyword,
        setKeyword,
      }}
    >
      {children}
    </KeywordContext.Provider>
  );
};

export default KeywordProvider;
