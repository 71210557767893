import * as React from "react";
import Head from "next/head";

const googleAnalytics = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${process.env.GOOGLE_ANALYTICS}');
`;

export const GoogleTagManager: React.FC = () => {
  if (!process.env.GOOGLE_ANALYTICS || process.env.GOOGLE_ANALYTICS === "") {
    return null;
  }

  return (
    <Head>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{ __html: googleAnalytics }}
        defer
      />
    </Head>
  );
};

export default GoogleTagManager;
